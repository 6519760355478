import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 15px;
`

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  float: right;
  margin-bottom: 10px;
`

export const RowFormat = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ColFormat = styled.div`
  flex: auto; /* Faz com que cada coluna ocupe o mesmo espaço */
  //min-width: 200px; /* Tamanho mínimo para as colunas */
`;
export const WrapperDrop = styled.span`
  .ant-form-item-label {
   text-align: left !important;
  }
  .ant-row {
    flex-direction: column;
  }
`

export const UploadWrapper = styled.span`
  .ant-upload.ant-upload-drag {
    height: 75px;
  }
`

export const WrapperCurrency = styled.span`
  position: relative;
  span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 5px 0px 5px 11px;
  }
  input {
    padding-left: 32px;
  }
`

